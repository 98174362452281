/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
 */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  max-width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif; 
  background-color:  #F7F8F9;
  line-height: 30px;
}
#root{
  width: 100%;
  padding: 0;
  margin: 0;
}
code {
  font-family: 'Poppins',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (max-width:1650px){
  body{
    line-height: 30px;
  }
}

