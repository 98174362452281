//style
a {
  cursor: pointer;
}
h3 {
  color: white;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
}
.fontRadial {
  background: radial-gradient(rgba(255, 0, 0, 0), white);
  font-size: 16px;
}

.fullHeight {
  height: 100vh;
}

.h1 {
  font-weight: 700;
  font-size: 60px;
}

.h2 {
  font-size: 20px;
  font-weight: 500;
  color: #556987;
}

.buttonKolos {
  background-color: #6366f1;
  padding: 12px 16px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  color: #f7f8f9;
  cursor: pointer;

  a {
    font-size: 16px;
    font-weight: 300;
    color: #f0fdf4;
  }
}

.buttonKolos:hover {
  background-color: #3c3ff3;
}

//style avec sass
.conteneurNavigation {
  width: 100%;
  max-height: 80px;
  padding: 24px 0px;
  display: flex;
  justify-content: space-between;
  background-color: #f7f8f9;
  position: fixed;
  z-index: 999;
  top: 0;
  font-size: 16px;
  font-weight: 500;
  color: #556987;
  transition: top 0.3s ease-in-out;

  .leftItem {
    display: flex;
    align-items: center;

    span {
      margin-left: 64px;

      a {
        font-size: 16px;
        font-weight: 500;
        color: #556987;
      }
    }
  }

  .rightItem {
    display: flex;
    align-items: center;

    span {
      margin-right: 64px;

      a {
        font-size: 16px;
        font-weight: 500;
        color: #556987;
      }
    }

    .listIcon {
      display: none;
    }

    .buttonKolos {
      margin-right: 30px;
    }
  }
}

.containerNavMobile {
  display: none;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #f7f8f9;
  margin-left: -100vw;
  transition: margin-left 0.5s ease-in-out;

  .headNavMobile {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }

  .bodyNavMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;

    span {
      margin-top: 30px;

      a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #556987;
      }
    }
  }
}

.page1 {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  overflow: hidden;

  .leftItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3% 5%;
    width: 58%;
  }

  .rightItem {
    display: flex;
    align-items: center;
    height: 100%;
    width: 42%;
    position: relative;

    .decoration {
      position: absolute;
      top: 28%;
      left: -8%;
      height: 40%;
    }

    .image1 {
      z-index: 99;
      width: 100%;
      margin-top: 15%;
      margin-left: 10%;
    }
  }
}

.page2 {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/images/backgroundPage2.png");
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  background-size: cover;
  overflow: hidden;

  .headerPage2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5%;

    .firstItem {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .opcPage2 {
        text-align: center;
        background-color: #e0e7ff;
        min-width: 56px;
        padding: 2px 8px;
        font-size: 12px;
        font-weight: 500;
        color: #312e81;
        box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
        border-radius: 36px;
        margin-left: 30px;
      }

      .h1 {
        font-size: 36px;
        font-weight: 700;
        color: #ffffff;
      }

      .h2 {
        font-weight: 500;
        font-size: 20px;
        color: #bbc3cf;
      }

      .listIcons {
        width: 100%;
        padding: 20px 200px;
        display: flex;
        justify-content: space-between;

        div {
          display: flex;
          align-items: center;
          flex-direction: column;

          .buttonKolos {
            min-width: 64px;
            min-height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .buttonKolos:hover {
            background-color: #6366f1;
            cursor: default;
          }

          span {
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
  }

  .secondItem {
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 0px 150px;
    align-items: center;

    .partie1 {
      display: flex;
      align-items: center;
      justify-content: center;
      .image2Page2 {
        display: none;
      }
      img {
        margin: auto;
      }
    }

    .partie2 {
      margin-left: 100px;
      display: flex;
      flex-direction: column;

      span {
        font-size: 17px;
        font-weight: 500;
        line-height: 30px;
        color: #bbc3cf;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        span {
          margin-top: 5px;
          margin-left: 10px;
          display: flex;
          align-items: center;

          .iconChecked {
            min-width: 20px;
            min-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.badgeKolos {
  background-color: #e0e7ff;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 36px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  color: #6366f1;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page3 {
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  padding-top: 50px;
  overflow: hidden;
margin-bottom: 20px;
  .leftItem {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0px 100px;

    .badgeKolos {
      background-color: #e0e7ff;
      box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
      border-radius: 36px;
      width: 55px;
      padding: 2px 8px;
      font-size: 12px;
      font-weight: 500;
      color: #6366f1;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.02em;
      color: #2a3342;
    }

    .description {
      font-size: 20px;
      font-weight: 500;
      color: #556987;
      margin-bottom: 32px;
    }

    div {
      display: flex;
      align-items: self-start;
      margin-top: 10px;

      img {
        margin-right: 10px;
      }

      div {
        display: flex;
        flex-direction: column;

        .h2 {
          font-size: 24px;
          font-weight: 700;
          line-height: 10px;
          color: #2a3342;
        }

        .p {
          font-size: 16px;
          font-weight: 500;
          color: #556987;
          line-height: 30px;
          text-align: justify;
        }
      }
    }
  }

  .rightItem {
    width: 50%;
    overflow: hidden;
  }
}

.page4 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  overflow: hidden;
  .page4Container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 30px;
    .item{
      width: 50%;
      padding-left: 50px;
      display: flex;
      align-items: self-start;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 20px;
      cursor: pointer;

      .info {
        display: flex;
        flex-direction: column;

        .titre {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          color: #2a3342;
          margin-left: 20px;
        }

        .description {
          margin-left: 20px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #556987;
        }
      }
    }
    .image{
      width: 50%;
      
    }

  }
  .leftItem {
    width: 50%;
    display: flex;

    .containerImage {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      .image {
        width: 90%;
      }
    }
  }

  .rightItem {
    width: 50%;
    border-left: solid #bbc3cf 2px;
    padding-right: 50px;
    margin-top: 30px;
    overflow: hidden;

    .headerPage4 {
      display: flex;
      flex-direction: column;
      padding-left: 50px;

      .badgeKolos {
        background-color: #e0e7ff;
        box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
        border-radius: 36px;
        max-width: 120px;
        padding: 2px 8px;
        font-size: 12px;
        font-weight: 500;
        color: #6366f1;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }

      .h1 {
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        color: #2a3342;
      }

      .h2 {
        font-size: 20px;
        font-weight: 500;
        color: #556987;
        margin-bottom: 20px;
      }
    }

    .item {
      width: 100%;
      padding-left: 50px;
      display: flex;
      align-items: self-start;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 20px;
      cursor: pointer;

      .info {
        display: flex;
        flex-direction: column;

        .titre {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          color: #2a3342;
          margin-left: 20px;
        }

        .description {
          margin-left: 20px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #556987;
        }
      }
    }
    .image {
      display: none;
    }
  }
}

.page5 {
  width: 100%;
  min-height: 100vh;
  margin-top: 200px;
  position: relative;
  background-image: url("../images/Background.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  position: relative;

  .zone1 {
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2c3544;
    padding: 3% 10%;
    text-align: center;
    border-radius: 6px;

    .h1 {
      font-size: 36px;
      font-weight: 700;
      color: #ffffff;
    }

    .h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: #8896ab;
    }

    .buttonKolos {
      max-width: 300px;
      margin-top: 20px;
    }
  }

  .info {
    margin-top: 16%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      background-color: #e0e7ff;
      padding: 2px 8px;
      text-align: center;
      max-width: 178px;
      font-size: 12px;
      font-weight: 500;
      color: #6366f1;
      margin-top: 20px;
    }

    .h1 {
      font-size: 48px;
      font-weight: 700;
      color: #2a3342;
    }

    .h2 {
      font-size: 20px;
      font-weight: 500;
      color: #556987;
    }

    .items {
      margin-top: 20px;
      width: 80%;
      display: flex;
      justify-content: space-between;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nb1 {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          background-color: #6366f1;
        }

        .nb2 {
          font-size: 20px;
          font-weight: 700;
          color: #2a3342;
          margin-top: 5px;
        }

        .nb3 {
          font-size: 16px;
          font-weight: 500;
          color: #4d5f7a;
        }
      }
    }

    .info2 {
      width: 80%;
      margin-top: 40px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #556987;
    }
  }
}

.listReference {
  background-image: url("../images/Footer.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  overflow: hidden;

  span {
    font-size: 20px;
    font-weight: 500;
    color: #2a3342;
    text-transform: uppercase;
  }

  .listItem {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    img {
      height: 100px;
    }
  }
}

.page6 {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  position: relative;

  .item1 {
    background-image: url("../images/BackgroundFooter.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 8% 8%;
    display: flex;
    align-items: center;

    .part1 {
      width: 60%;
      display: flex;
      flex-direction: column;

      .h1 {
        font-size: 48px;
        font-weight: 700;
        color: #ffffff;
      }

      .h2 {
        font-size: 20px;
        font-weight: 500;
        color: #bbc3cf;
        line-height: 30px;
      }
    }

    .part2 {
      min-width: 200px;
      margin-left: 10%;
    }
  }

  .item2 {
    display: flex;
    justify-content: space-between;
    padding: 5% 8%;
    background-image: url("../images/Footer.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .list {
      display: flex;
      flex-direction: column;
      width: 20%;
      margin-bottom: 10px;

      .titre {
        font-size: 18px;
        font-weight: 700;
        color: #2a3342;
        margin-bottom: 10px;
      }

      .description {
        font-size: 18px;
        font-weight: 500;
        color: #556987;
        line-height: 28px;
      }
    }
  }

  .marque {
    text-align: center;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #556987;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.page7 {
  width: 100%;
  overflow: hidden;
  position: relative;

  .item1 {
    background-image: url("../images/BackgroundFooter.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 8% 8%;
    display: flex;
    align-items: center;

    .part1 {
      width: 60%;
      display: flex;
      flex-direction: column;

      .h1 {
        font-size: 48px;
        font-weight: 700;
        color: #ffffff;
      }

      .h2 {
        font-size: 20px;
        font-weight: 500;
        color: #bbc3cf;
        line-height: 30px;
      }
    }

    .part2 {
      min-width: 200px;
      margin-left: 10%;
    }
  }

  .item2 {
    display: flex;
    justify-content: space-between;
    padding: 5% 8%;
    background-image: url("../images/Footer.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .list {
      display: flex;
      flex-direction: column;
      width: 20%;
      margin-bottom: 10px;

      .titre {
        font-size: 18px;
        font-weight: 700;
        color: #2a3342;
        margin-bottom: 10px;
      }

      .description {
        font-size: 18px;
        font-weight: 500;
        color: #556987;
        line-height: 28px;
      }
    }
  }

  .marque {
    text-align: center;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #556987;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.modalPlaquette {
  z-index: 9999999999;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  .containerPlaquette {
    background-color: white;
    width: 50%;
    border: 1px solid #e2e8f0;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 6.15px 12.31px rgba(0, 0, 0, 0.06),
      0px 6.15px 6.15px rgba(0, 0, 0, 0.08);

    .headerPlaquette {
      background-color: #ebeff5;
      width: 100%;
      padding: 40px 40px;
      text-align: center;
      font-size: 30px;
      font-weight: 700;
    }

    .bodyPlaquette {
      padding: 20px 40px;

      div {
        label {
          font-size: 18px;
          font-weight: 700;
          color: #4d5f7a;

          span {
            color: red;
            margin-right: 10px;
          }
        }
      }
    }

    .footerPlaquette {
      padding: 20px 40px;
      display: flex;
      justify-content: space-between;

      .buttonCloseKolos {
        background-color: #ebeff5;
        padding: 12px 16px;
        max-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #4d5f7a;
        cursor: pointer;
      }

      .buttonCloseKolos:hover {
        background-color: #c8ced6;
      }
    }
  }
}

//Taille ecran < 1650 px
@media (min-Width:1600px) {
  .page4{
    width: 1600px;
    margin: auto;
  }
}
@media (max-width: 1650px) {
  .page1 {
    .leftItem {
      // Commentaire
    }

    .rightItem {
      .decoration {
        top: 30%;
      }

      .image1 {
        height: 70%;
      }
    }
  }
}

//Taille ecran < 1300px
@media (max-width: 1300px) {
  .page5 {
    .info {
      margin-top: 18%;
    }
  }

  .listReference {
    span {
      margin-top: 30px;
    }

    .listItem {
      flex-direction: row;

      img {
        margin-top: 30px;
      }
    }
  }
}

//Taille ecran < 1200px
@media (max-width: 1200px) {
  .conteneurNavigation {
    width: 100%;
    overflow: hidden;
  }

  .page1 {
    overflow: hidden;
    flex-direction: column;
    margin-top: 10%;
    justify-content: center;

    .leftItem {
      width: 100%;
    }

    .rightItem {
      width: 100%;

      .decoration {
        height: 60%;
        left: 15%;
        top: 20%;
      }

      .image1 {
        width: 70%;
        height: auto;
        margin-top: 5%;
        margin-left: 30%;
      }
    }
  }

  .page2 {
    overflow: hidden;
    flex-direction: column;

    .headerPage2 {
      .firstItem {
        width: 100%;

        .h1 {
          width: 100%;
          text-align: center;
        }

        .listIcons {
          padding: 20px 10px;
          flex-direction: column;

          div {
            margin-top: 20px;
          }
        }
      }
    }

    .secondItem {
      flex-direction: column;
      width: 100%;
      padding: 20px 30px;

      .partie2 {
        padding: 0px 0px;
        margin: 0px;
        margin-top: 20px;
        text-align: center;
      }
    }
  }

  .page3 {
    overflow: hidden;
    flex-direction: column;

    .leftItem {
      width: 100%;
    }

    .rightItem {
      width: 100%;
      margin-top: 50px;
      text-align: center;
    }
  }

  .page4 {
    .leftItem {
      .containerImage {
        .image {
          width: 90%;
        }
      }
    }
  }

  .page5 {
    .info {
      margin-top: 25%;
    }
  }
}

//Taille ecran < 1000px
@media (max-width: 1000px) {
  .page4 {
    flex-direction: column;
    .reverse{
      .image{
        order: 2;
      }
      .item{
        order: 1;
      }
    }
    .page4Container{
      flex-direction: column;
      .item{
        width: 100%;
      }
      .image{
        width: 100%;
      }
    }
    .leftItem {
      display: none;
    }

    .rightItem {
      width: 100%;

      .item {
        .info {
          .description {
            display: block;
          }
        }
      }
      .image {
        display: block;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        width: 90%;
      }
    }
  }

  .page5 {
    margin-top: 0px;

    .zone1 {
      .h1 {
        font-size: 20px;
      }

      .h2 {
        font-size: 14px;
      }
    }

    .info {
      margin-top: 16%;

      .h1 {
        font-size: 35px;
      }

      .h2 {
        font-size: 16px;
      }

      .info2 {
        font-size: 14px;
      }
    }
  }

  .modalPlaquette {
    .containerPlaquette {
      margin-top: 2%;
      width: 80%;
    }
  }

  .listReference {
    .listItem {
      flex-direction: column;

      img {
        margin-top: 40px;
      }
    }
  }
}

//Taille ecran <900px
@media (max-width: 900px) {
  .conteneurNavigation {
    width: 100%;
    overflow: hidden;

    .leftItem {
      .solution {
        display: none;
      }

      .contact {
        display: none;
      }

      .temoignage {
        display: none;
      }
    }

    .rightItem {
      .seConnecter {
        display: none;
      }

      .listIcon {
        display: block;
      }
    }
  }

  .containerNavMobile {
    display: block !important;
  }

  .page3 {
    .rightItem {
      width: 100%;

      img {
        width: 80%;
      }
    }
  }

  .page5 {
    .info {
      margin-top: 30%;

      .items {
        flex-direction: column;

        .item {
          margin-top: 30px;
        }
      }

      .h1 {
        text-align: center;
      }
    }
  }

  .page6 {
    .item1 {
      flex-direction: column;

      .part1 {
        width: 100%;
      }

      .part2 {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
      }
    }

    .item2 {
      flex-direction: column;
      margin-bottom: 30%;
      .list {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
  .page7 {
    .item1 {
      flex-direction: column;

      .part1 {
        width: 100%;
      }

      .part2 {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
      }
    }

    .item2 {
      flex-direction: column;
      margin-bottom: 30%;
      .list {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
}

@media (max-width: 600px) {
  .conteneurNavigation {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;

    .leftItem {
      span {
        margin-left: 10px;
      }
    }

    .rightItem {
      .buttonKolos {
        width: 146px;
        font-size: 12px;
        font-weight: 500;
        padding: 5px 8px;
        text-align: center;
        margin-right: 0px;
        margin-left: 0px;
      }

      span {
        margin-right: 10px;
      }

      .listIcon {
        margin-left: 0px;
      }
    }
  }

  .page1 {
    margin-top: 70px;
    .leftItem {
      .h1 {
        font-size: 45px;
        font-weight: 800;
        line-height: 44px;
      }

      .h2 {
        font-size: 16px;
        font-weight: 500;
      }

      .buttonKolos {
        width: 319px;
        margin: auto;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .rightItem {
      width: 100%;
      padding: 30px 0px;
      margin: 0;
      display: flex;
      justify-content: end;

      .image1 {
        width: 90%;
        margin: 0;
      }
    }
  }

  .page2 {
    .headerPage2 {
      margin-top: 40px;

      .firstItem {
        .opcPage2 {
          padding: 2px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
        }

        .h1 {
          font-size: 24px;
          font-weight: 700;
        }

        .h2 {
          font-size: 16px;
          font-weight: 500;
        }

        .listIcons {
          div {
            margin-top: 30px;

            span {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .secondItem {
      padding: 20px 15px;
      .partie1 {
        .image1Page2 {
          display: none;
        }
        .image2Page2 {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .partie2 {
        span {
          font-size: 16px;
          font-weight: 500;
          text-align: left;
        }
        div {
          width: 100%;
          display: flex;
          span {
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            margin: 0;
            margin-top: 10px;
            align-items: self-start;
            .iconChecked {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .page3 {
    .leftItem {
      width: 100%;
      padding: 0px 15px;
      .h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
      .description {
        font-size: 16px;
        font-weight: 500;
      }
      div {
        width: 100%;
        display: flex;
        align-items: self-start;
        img {
          width: 32px;
          height: 32px;
        }
        div {
          margin: 0;
          .h2 {
            font-size: 18px;
            font-weight: 700;
          }
          .p {
            font-size: 16px;
            font-weight: 500;
            text-align: left;
          }
        }
        .buttonKolos {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .rightItem {
      width: 100%;
      padding: 0px 15px;
      img {
        width: 100%;
      }
    }
  }
  .modalPlaquette {
    padding: 0px 15px;
    .containerPlaquette {
      width: 100%;
      .headerPlaquette {
        font-size: 19px;
        font-weight: 800;
      }
      .bodyPlaquette {
        padding: 20px 15px;
        div {
          label {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
      .footerPlaquette {
        padding: 20px 15px;
        .buttonCloseKolos {
          font-size: 12px;
          font-weight: 600;
        }
        .buttonKolos {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
  .page4 {
    width: 100%;
    padding: 40px 0px;
    .rightItem {
      width: 100%;
      padding: 20px 15px;
      border: none;
      .headerPage4 {
        padding: 0;
        .badgeKolos {
          margin: auto;
        }
        .h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          text-align: center;
        }
        .h2 {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
        }
      }
      .item {
        padding: 0;
        padding-left: 15px;
        border-left: solid #6366f1 1px;
        .info {
          .titre {
            font-size: 18px;
            font-weight: 700;
          }
          .description {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .page5 {
    width: 100%;
    padding: 20px 15px;
    margin-top: 30px;
    .zone1 {
      width: 94%;
      .h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
      .h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .buttonKolos {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .info {
      margin-top: 50%;
      .h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
      .h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }
      .items {
        .item {
          .nb2 {
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
          }
          .nb3 {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }
      }
      .info2 {
        width: 100%;
        padding: 20px 15px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }
    }
  }
  .listReference {
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
    }
    .listItem {
      img {
        margin-bottom: 30px;
      }
    }
  }
  .page6 {
    .item1 {
      padding: 50px 15px;
      margin: 0;
      .part1 {
        .h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
        }
        .h2 {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .part2 {
        .buttonKolos {
          font-size: 16px;
        }
      }
    }
    .item2 {
      width: 100%;
      padding: 30px 15px;
      margin-bottom: 30%;
      .list {
        .titre {
          font-size: 16px;
          font-weight: 700;
        }
        .description {
          font-size: 14px;
          font-weight: 500;
        }
        .buttonKolos {
          font-size: 14px;
          padding: 8px 18px;
        }
      }
    }
  }
  .page7 {
    .item1 {
      padding: 50px 15px;
      margin: 0;
      .part1 {
        .h1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
        }
        .h2 {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .part2 {
        .buttonKolos {
          font-size: 16px;
        }
      }
    }
    .item2 {
      width: 100%;
      padding: 30px 15px;
      margin-bottom: 30%;
      .list {
        .titre {
          font-size: 16px;
          font-weight: 700;
        }
        .description {
          font-size: 14px;
          font-weight: 500;
        }
        .buttonKolos {
          font-size: 14px;
          padding: 8px 18px;
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .conteneurNavigation {
    .rightItem {
      .buttonKolos {
        display: none;
      }
    }
  }

  .page1 {
    margin-top: 90px;
    .rightItem {
      display: flex;
      padding: 30px 0px;
      justify-content: end;
      text-align: right;
      .image1 {
        width: 100%;
      }
    }
  }
}


.pageTemoignage {
  width: 100%;
  min-height: 50vh;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  // background-color: #f7f8f9; // Assortie aux autres sections avec un fond clair
  background-image: url("../images/Footer.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  overflow: hidden;

  .badgeKolosTemoignage {
    background-color: #e0e7ff;
    box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
    border-radius: 36px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 500;
    color: #6366f1;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .temoignageContainer {
    width: 80%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;    
    
    .iconTemoignage {
      background-color: #e0e7ff;
      padding: 2px 8px;
      text-align: center;
      max-width: 178px;
      font-size: 30px;
      font-weight: 500;
      color: #6366f1;
      margin-top: 20px;
    }

    .titreTemoignage {
      font-size: 48px;
      font-weight: 700;
      color: #2a3342; // Couleur cohérente avec les autres titres
      margin-bottom: 40px;
      padding-top: 0.4em;

      @media(max-width: 1000px) {
        font-size: 35px;
      }

      @media(max-width: 600px) {
        font-size: 25px;
      }
    }

    .temoignageContent {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media(min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .temoignageVideo {
        width: 100%;
        max-width: 560px;
        margin-bottom: 20px;

        iframe {
          width: 100%;
          height: auto;
          border-radius: 10px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Ajout d'un léger effet d'ombre
        }
      }

      .temoignageText {
        font-size: 18px;
        font-weight: 500;
        color: #556987;
        line-height: 28px;
        margin-top: 20px;
        max-width: 500px;

        @media(min-width: 768px) {
          margin-left: 20px;
          margin-top: 0;
        }

        p {
          margin-bottom: 10px;

          &:last-child {
            font-style: italic;
            font-weight: bold;
          }
        }
      }
    }
  }
}